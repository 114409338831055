import React from 'react'
import Table, { ColumnsType } from 'antd/es/table'
import { horsesApi } from 'api/horses'
import { PageWrapper, MainTitle } from 'components/ui'
import { EHorseStatus, IPopulatedHorseWithoutCount } from 'models/horse'
import { showServerError } from 'shared/utils/showServerError'
import { Button, message, Modal, Space, Typography } from 'antd'
import { MenuInfo } from 'rc-menu/lib/interface'
import moment from 'moment'
import { phoneMaskStatic } from 'shared/utils/phoneMaskStatic'
import { StatusDropdown } from 'components/ui/StatusDropdown/StatusDropdown'
import { getHorseStatusItems, horseStatuses } from 'shared/constants/horses'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { bodyScrollLock } from 'shared/utils/bodyScrollLock'
import { useSearchParams } from 'react-router-dom'
import { cleanObject } from 'shared/utils/cleanObject'
import qs from 'qs'

export const Horses: React.FC = () => {
  const [, setSearchParams] = useSearchParams()

  const [horses, setHorses] = React.useState<IPopulatedHorseWithoutCount[]>([])
  const [isLoading, setIsLoading] = React.useState(false)

  const [selectedItemsId, setSelectedItemsId] = React.useState<string[]>([])

  const [currentPage, setCurrentPage] = React.useState(1)
  const [total, setTotal] = React.useState(0)
  const pageSize = 10

  const getHorses = async (skip: number) => {
    try {
      setIsLoading(true)
      const response = await horsesApi.getHorses({
        skip: String(skip),
        limit: String(pageSize)
      })
      setTotal(response.data.count)
      setHorses(response.data.horses)
      setSearchParams(cleanObject({
        page: skip / pageSize + 1 === 1 ? '' : String(skip / pageSize + 1)
      }))
    } catch (e) {
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if (window.location.search) {
      const params = qs.parse(window.location.search.substring(1))
      const page = Number(params.page)
      if (!isNaN(page)) {
        setCurrentPage(page)
        getHorses((page - 1) * pageSize)
      }
    } else {
      getHorses(0)
    }
  }, [])

  const onChangeHorseStatus = async (horseId: string, e: MenuInfo) => {
    try {
      setIsLoading(true)
      await horsesApi.changeHorseStatus(horseId, e.key as EHorseStatus)
      setHorses((prev) =>
        prev.map((el) => {
          if (el._id === horseId) {
            return {
              ...el,
              status: e.key as EHorseStatus
            }
          }
          return el
        })
      )
    } catch (e) {
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  const setProductsAction = async () => {
    try {
      await horsesApi.setActions({
        toDelete: selectedItemsId.join(',')
      })
      message.success(selectedItemsId.length === 1 ? 'Лошадь успешно удалена' : 'Выбранные лошади успешно удалены')
      if (selectedItemsId.length === horses.length) {
        getHorses(0)
        setCurrentPage(1)
      } else {
        getHorses((currentPage - 1) * pageSize)
      }
      setSelectedItemsId([])
    } catch (e) {
      showServerError(e)
    } finally {
      bodyScrollLock.disable()
    }
  }

  const showDeleteConfirm = () => {
    bodyScrollLock.enable()
    Modal.confirm({
      centered: true,
      title: 'Вы действительно хотите удалить выбранные лошади?',
      content: 'Отменить данное действие будет невозможно.',
      icon: <ExclamationCircleOutlined />,
      okText: 'Удалить',
      cancelText: 'Закрыть',
      onOk() {
        return new Promise((resolve) => {
          setProductsAction().then(resolve)
        })
      },
      onCancel() {
        bodyScrollLock.disable()
      }
    })
  }

  const onPageChange = (current: number) => {
    getHorses((current - 1) * pageSize)
    setCurrentPage(current)
  }

  const onRowSelection = (selectedItemsId: React.Key[]) => {
    setSelectedItemsId(selectedItemsId as string[])
  }

  const columns: ColumnsType<IPopulatedHorseWithoutCount> = React.useMemo(
    () => [
      {
        title: 'Номер',
        dataIndex: 'number',
        width: 101
      },
      {
        title: 'Дата',
        dataIndex: 'date',
        width: 192,
        render: (_, { createdAt }) => {
          return <Typography.Text>{moment(createdAt).format('DD.MM.YYYY')}</Typography.Text>
        }
      },
      {
        title: 'Продавец',
        dataIndex: 'seller',
        width: 192,
        render: (_, { user_id }) => {
          return (
            <Typography.Text style={{ opacity: user_id?.full_name ? 1 : 0.5 }}>{user_id?.full_name ?? 'Удаленный аккаунт'}</Typography.Text>
          )
        }
      },
      {
        title: 'Телефон',
        dataIndex: 'sellerPhone',
        width: 192,
        render: (_, { user_id }) => {
          return (
            <Typography.Text style={{ opacity: user_id?.tel ? 1 : 0.5 }}>{user_id?.tel ? phoneMaskStatic(user_id.tel) : 'Удаленный аккаунт'}</Typography.Text>
          )
        }
      },
      {
        title: 'Лошадь',
        dataIndex: 'name',
        width: 192
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        width: 154,
        render: (_, { _id, status }) => {
          return (
            <StatusDropdown
              items={getHorseStatusItems(status)}
              label={horseStatuses[status].label}
              color={horseStatuses[status].color}
              onClick={(e) => onChangeHorseStatus(_id, e)}
            />
          )
        }
      }
    ],
    [horses]
  )

  return (
    <PageWrapper>
      <MainTitle text='Лошади' />
      <Table
        scroll={{ x: 950 }}
        columns={columns}
        dataSource={horses}
        loading={isLoading}
        rowKey={(el) => el._id}
        rowSelection={{
          selectedRowKeys: selectedItemsId,
          onChange: onRowSelection
        }}
        rowClassName='tableRow'
        footer={() => (
          <Space size={8}>
            <Typography.Text>Действия:</Typography.Text>
            <Button
              danger
              type='primary'
              icon={<DeleteOutlined />}
              onClick={showDeleteConfirm}
              disabled={!selectedItemsId.length}
            >
              Удалить
            </Button>
          </Space>
        )}
        pagination={{
          current: currentPage,
          onChange: onPageChange,
          pageSize,
          total,
          showSizeChanger: false
        }}
      />
    </PageWrapper>
  )
}