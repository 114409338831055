import {
  Button,
  Input,
  InputRef,
  Space,
  Typography,
  Table,
  Select,
  Tag,
  Radio,
  DatePicker,
  Modal
} from 'antd'
import React from 'react'
import { CheckCircleFilled, FilterFilled, SearchOutlined } from '@ant-design/icons'
import { AxiosError } from 'axios'
import { useNavigate, useSearchParams } from 'react-router-dom'
import classNames from 'classnames'
import { MenuInfo } from 'rc-menu/lib/interface'
import moment from 'moment'
import qs from 'qs'
import dayjs from 'dayjs'
import debounce from 'lodash.debounce'
import { ColumnsType } from 'antd/es/table'
import { MainTitle } from 'components/ui/MainTitle/MainTitle'
import { StatusDropdown } from 'components/ui/StatusDropdown/StatusDropdown'
import { EOrderStatus, ERecieveMethod, IOrdersQueryFilters, ITableOrder } from 'models/orders'
import { getOrderPath } from 'routing/utils/getOrderPath'
import { showServerError } from 'shared/utils/showServerError'
import { ISeller } from 'models/seller'
import { sellerApi } from 'api/seller'
import { ordersApi } from 'api/orders'
import { useAppSelector } from 'shared/hooks/useReduxHooks'
import { getNormalizedPrice } from 'shared/utils/getNormalizedPrice'
import { getOrderStatusItems, orderTags } from 'shared/constants/orders'
import { phoneMaskStatic } from 'shared/utils/phoneMaskStatic'
import { superAdminRoutes } from 'shared/constants/routes'
import { appSelectors } from 'store/app'
import { ERoles } from 'models/auth'
import { IDateFilter } from 'models/app'
import { acceptOrderModal } from 'shared/utils/orders/acceptOrderModal'
import { bodyScrollLock } from 'shared/utils/bodyScrollLock'
import { cleanObject } from 'shared/utils/cleanObject'
import { DEBOUNCE_DELAY } from 'shared/constants/app'
import localeRu from 'antd/es/date-picker/locale/ru_RU'
import { CancelOrder } from '../CancelOrder/CancelOrder'
import styles from './OrdersList.module.scss'
import 'dayjs/locale/ru'

interface IOrdersListProps {
  sellerId?: string
}

type TPriceOrder = 'asc' | 'desc' | undefined

// const getBuyerName = (item: ITableOrder): React.ReactNode => {
//   if (item.delivery_info?.full_name) {
//     return <Typography.Text>{item.delivery_info.full_name}</Typography.Text>
//   }
//   if (item.user_id) {
//     if (item.user_id.name) {
//       return <Typography.Text>{item.user_id.name}</Typography.Text>
//     }
//     return (
//       <Typography.Text style={{ opacity: 0.5 }}>
//         Не заполненный профиль
//       </Typography.Text>
//     )
//   }
//   return (
//     <Typography.Text style={{ opacity: 0.5 }}>
//       Удаленный аккаунт
//     </Typography.Text>
//   )
// }

export const OrdersList: React.FC<IOrdersListProps> = ({ sellerId }) => {
  const role = useAppSelector(appSelectors.selectRole)
  const isSuperAdmin = role === ERoles.ADMIN

  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()

  const [isFirstRequestFinished, setIsFirstRequestFinished] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [orders, setOrders] = React.useState<ITableOrder[]>([])
  const [sellers, setSellers] = React.useState<ISeller[]>([])

  const [openCancelOrderModal, setOpenCancelOrderModal] = React.useState(false)
  const [selectedOrderId, setSelectedOrderId] =
    React.useState<string | null>(null)

  const [globalSearch, setGlobalSearch] = React.useState('')

  const [sortDate, setSortData] = React.useState<IDateFilter | null>(null)
  const [datePickerValue, setDatePickerValue] = React.useState<any>(null)

  const [orderNumberSearch, setOrderNumberSearch] = React.useState('')
  const orderNumberSearchRef = React.useRef<InputRef>(null)

  const [sellerSearch, setSellerSearch] = React.useState<string | undefined>(
    undefined
  )
  const [selectedSellerId, setSelectedSellerId] = React.useState('')

  const [phoneBuyerSearch, setPhoneBuyerSearch] = React.useState('')
  const phoneBuyerSearchRef = React.useRef<InputRef>(null)

  const [priceOrder, setPriceOrder] = React.useState<TPriceOrder>(undefined)

  const [status, setStatus] = React.useState<EOrderStatus | undefined>(
    undefined
  )

  const [currentPage, setCurrentPage] = React.useState(1)
  const [total, setTotal] = React.useState(0)
  const pageSize = 10

  const getOrders = async (skip: number, status: EOrderStatus | undefined, priceOrder: TPriceOrder, data?: IOrdersQueryFilters) => {
    try {
      setIsLoading(true)
      const response = await ordersApi.getOrders({
        skip: String(skip),
        limit: String(pageSize),
        name: data?.name ?? globalSearch,
        from: data?.from ?? sortDate?.from,
        to: data?.to ?? sortDate?.to,
        number: data?.number ?? orderNumberSearch,
        tel: data?.tel ?? phoneBuyerSearch,
        seller_id: sellerId ?? data?.seller_id ?? selectedSellerId,
        sum: priceOrder,
        status
      })
      setTotal(response.data.count)
      setOrders(response.data.orders)
      setSearchParams(cleanObject({
        page: skip / pageSize + 1 === 1 ? '' : String(skip / pageSize + 1),
        name: data?.name ?? globalSearch,
        from: data?.from ?? sortDate?.from ?? '',
        to: data?.to ?? sortDate?.to ?? '',
        number: data?.number ?? orderNumberSearch,
        tel: data?.tel ?? phoneBuyerSearch,
        seller_id: data?.seller_id ?? selectedSellerId ?? '',
        sum: priceOrder ?? '',
        status: status ?? ''
      }))
    } catch (e) {
      const error = e as AxiosError
      if (
        (error.response?.status === 500 ||
        error.response?.status === 400 ||
        error.response?.status === 404) && isSuperAdmin
      ) {
        return navigate(superAdminRoutes.SELLERS)
      }
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if (window.location.search) {
      const params = qs.parse(window.location.search.substring(1))
      if (params.page) {
        setCurrentPage(Number(params.page))
      }
      if (params.name) {
        setGlobalSearch(params.name as string)
      }
      if (params.from || params.to) {
        setSortData({
          from: params?.from as string ?? '',
          to: params?.to as string ?? ''
        })
        setDatePickerValue([
          params?.from ? dayjs(params?.from as string) : '',
          params?.to ? dayjs(params?.to as string) : ''
        ])
      }
      if (params.number) {
        setOrderNumberSearch((params.number as string).replace(/[^0-9]/g, ''))
      }
      if (params.tel) {
        setPhoneBuyerSearch((params.tel as string).replace(/[^0-9]/g, ''))
      }
      if (params.seller_id) {
        setSelectedSellerId(params.seller_id as string)
      }
      if (params.sum) {
        setPriceOrder(params.sum as any)
      }
      if (params.status) {
        setStatus(params.status as EOrderStatus)
      }
    }
    setIsFirstRequestFinished(true)
  }, [])

  React.useEffect(() => {
    if (sellers.length) {
      setSellerSearch(sellers.find(el => el._id === selectedSellerId)?.shop_details?.shop_name)
    }
  }, [sellers])

  const saveFieldValue = (data: IOrdersQueryFilters) => {
    getOrders(0, status, priceOrder, data)
    setCurrentPage(1)
  }

  const onPageChange = (current: number) => {
    getOrders((current - 1) * pageSize, status, priceOrder)
    setCurrentPage(current)
  }

  const onChangeGlobalSearch = (searchValue: string) => {
    getOrders(0, status, priceOrder, { name: searchValue })
    setCurrentPage(1)
  }

  const onChangePriceOrder = (newPriceOrder: TPriceOrder) => {
    getOrders(0, status, newPriceOrder)
    setCurrentPage(1)
  }

  const onChangeStatus = (newStatus: EOrderStatus | undefined) => {
    getOrders(0, newStatus, priceOrder)
    setCurrentPage(1)
  }

  const debouncedGetOrdersByGlobalSearch = React.useCallback(debounce(onChangeGlobalSearch, DEBOUNCE_DELAY), [sortDate, orderNumberSearch, selectedSellerId, phoneBuyerSearch, priceOrder, status])

  React.useEffect(() => {
    if (isFirstRequestFinished) {
      getOrders((currentPage - 1) * pageSize, status, priceOrder)
      setCurrentPage(currentPage)
    }
  }, [isFirstRequestFinished])

  React.useEffect(() => {
    if (isSuperAdmin && !sellerId) {
      const getSellers = async () => {
        try {
          const response = await sellerApi.getSearchSellers()
          setSellers(response.data)
        } catch (e) {
          showServerError(e)
        }
      }
      getSellers()
    }
  }, [])

  const showSuccessModal = () => {
    bodyScrollLock.enable()
    Modal.confirm({
      centered: true,
      title: 'Заказ принят в работу',
      content:
        'Статус в заказе покупателя также сменится на статус “Принят в работу”',
      icon: <CheckCircleFilled style={{ color: '#52C41A' }} />,
      okText: 'Ок',
      cancelText: 'Закрыть',
      onOk() {
        bodyScrollLock.disable()
      },
      onCancel() {
        bodyScrollLock.disable()
      }
    })
  }

  const changeStatus = async (
    status: EOrderStatus,
    orderId?: string,
    cancelReason?: string
  ) => {
    try {
      setIsLoading(true)
      const normalizedOrderId = orderId ?? selectedOrderId ?? ''
      await ordersApi.changeStatus({
        status,
        orderId: normalizedOrderId,
        reason_of_cancel: cancelReason
      })
      setOrders((prev) =>
        prev.map((el) => {
          if (el._id === normalizedOrderId) {
            return {
              ...el,
              status
            }
          }
          return el
        })
      )
      setSelectedOrderId(null)
      if (role === ERoles.USER && status === EOrderStatus.CONFIRMED_BY_SELLER) {
        showSuccessModal()
      }
    } catch (e) {
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  const closeOrderCanceledModal = React.useCallback(() => {
    setOpenCancelOrderModal(false)
    bodyScrollLock.disable()
  }, [])

  const openOrderCanceledModal = React.useCallback(() => {
    setOpenCancelOrderModal(true)
    bodyScrollLock.enable()
  }, [])

  const changeToCanceledStatus = (cancelReason: string) => changeStatus(EOrderStatus.CANCELED, undefined, cancelReason)

  const onTagClick = React.useCallback((e: MenuInfo, orderId: string) => {
    if (e.key === EOrderStatus.CONFIRMED_BY_SELLER) {
      return acceptOrderModal(async () => {
        await changeStatus(EOrderStatus.CONFIRMED_BY_SELLER, orderId)
        bodyScrollLock.disable()
      })
    }

    if (e.key === EOrderStatus.CANCELED) {
      setSelectedOrderId(orderId)
      return openOrderCanceledModal()
    }
    
    changeStatus(e.key as EOrderStatus, orderId)
  }, [])

  const normalizedSellers = React.useMemo(() => {
    return sellers.map((item) => ({
      label: item.shop_details.shop_name,
      value: item.shop_details.shop_name,
      key: item._id
    }))
  }, [sellers])

  const columns: ColumnsType<ITableOrder> = React.useMemo(
    () => {
      const baseColumns: ColumnsType<ITableOrder> = [
        {
          title: isSuperAdmin ? 'ID' : 'Номер заказа',
          dataIndex: 'number',
          width: isSuperAdmin ? sellerId ? '17%' : '12%' : '25%',
          onCell: ({ _id }) => {
            return {
              onClick: () => navigate(getOrderPath(_id))
            }
          },
          render: (_, { number }) => {
            return (
              <Typography.Text style={{ wordWrap: 'break-word' }} onClick={(event) => event.stopPropagation()}>
                {number}
              </Typography.Text>
            )
          },
          filterDropdown: ({ close }) => {
            return (
              <div
                className='filterWrapper'
                onKeyDown={(e) => e.stopPropagation()}
              >
                <Input
                  ref={orderNumberSearchRef}
                  value={orderNumberSearch}
                  onChange={(e) => setOrderNumberSearch(e.target.value.replace(/[^0-9]/g, ''))}
                  onPressEnter={() => {
                    saveFieldValue({ number: orderNumberSearch })
                    close()
                  }}
                  placeholder='Номер заказа'
                  className='filterInput'
                />
                <div className='filterFooterButtons'>
                  <Button
                    type='primary'
                    onClick={() => {
                      saveFieldValue({ number: orderNumberSearch })
                      close()
                    }}
                    icon={<SearchOutlined />}
                    size='small'
                    className='filterButton'
                  >
                    Найти
                  </Button>
                  <Button
                    onClick={() => {
                      setOrderNumberSearch('')
                      saveFieldValue({ number: '' })
                      close()
                    }}
                    size='small'
                    className='filterButton'
                  >
                    Очистить
                  </Button>
                  <Button
                    type='link'
                    size='small'
                    onClick={() => {
                      close()
                    }}
                  >
                    Закрыть
                  </Button>
                </div>
              </div>
            )
          },
          onFilterDropdownOpenChange: (visible) => {
            if (visible) {
              setTimeout(() => orderNumberSearchRef.current?.select(), 100)
            }
          },
          filterIcon: () => (
            <SearchOutlined
              style={{
                color: orderNumberSearch ? '#1677ff' : 'rgba(0, 0, 0, 0.29)'
              }}
            />
          )
        },
        {
          title: 'Дата',
          dataIndex: 'createdAt',
          width: isSuperAdmin ? sellerId ? '18%' : '15%' : '25%',
          onCell: ({ _id }) => {
            return {
              onClick: () => navigate(getOrderPath(_id))
            }
          },
          render: (_, { createdAt }) => {
            return (
              <Typography.Text onClick={(event) => event.stopPropagation()}>
                {moment(createdAt).format('DD.MM.YYYY')}
              </Typography.Text>
            )
          }
        },
        {
          title: 'Сумма',
          dataIndex: 'full_sum',
          width: isSuperAdmin ? sellerId ? '20%' : '16%' : '25%',
          sorter: true,
          sortOrder: priceOrder === 'asc' ? 'ascend' : priceOrder === 'desc' ? 'descend' : undefined,
          onCell: ({ _id }) => {
            return {
              onClick: () => navigate(getOrderPath(_id))
            }
          },
          render: (_, { full_sum }) => {
            return (
              <Typography.Text onClick={(event) => event.stopPropagation()}>
                {getNormalizedPrice(full_sum)}
              </Typography.Text>
            )
          }
        },
        {
          title: 'Статус',
          dataIndex: 'status',
          width: isSuperAdmin ? '15%' : '25%',
          onCell: ({ _id }) => {
            return {
              onClick: () => navigate(getOrderPath(_id))
            }
          },
          render: (_, { status, _id, receive_method }) => {
            if (
              status === EOrderStatus.CANCELED ||
              status === EOrderStatus.NOT_PAID ||
              (status === EOrderStatus.FINISHED && !isSuperAdmin) ||
              (status === EOrderStatus.CONFIRMED_BY_SELLER && receive_method !== ERecieveMethod.PICKUP_POINT && !isSuperAdmin) ||
              (status === EOrderStatus.IN_DELIVERY && !isSuperAdmin) ||
              (status === EOrderStatus.WAITING_IN_POINT && receive_method !== ERecieveMethod.PICKUP_POINT && !isSuperAdmin)
            ) {
              return (
                <Tag color={orderTags[status].color} onClick={(event) => event.stopPropagation()}>
                  {orderTags[status].label}
                </Tag>
              )
            }
            return (
              <div onClick={(event) => event.stopPropagation()}>
                <StatusDropdown
                  items={getOrderStatusItems(status, role as ERoles, receive_method)}
                  label={orderTags[status].label}
                  color={orderTags[status].color}
                  onClick={(e) => onTagClick(e, _id)}
                />
              </div>
            )
          },
          filterDropdown: ({ close }) => {
            return (
              <div
                className='filterWrapper'
                onKeyDown={(e) => e.stopPropagation()}
              >
                <Radio.Group
                  onChange={(e) => {
                    setStatus(e.target.value)
                    onChangeStatus(e.target.value)
                    close()
                  }}
                  value={status}
                  style={{ display: 'block', marginBottom: 15 }}
                >
                  <Space direction='vertical' size={15}>
                    {Object.values(orderTags).map((item) => (
                      <Radio key={item.value as string} value={item.value}>
                        <Tag color={item.color}>{item.label}</Tag>
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
                <Button
                  onClick={() => {
                    setStatus(undefined)
                    onChangeStatus(undefined)
                    close()
                  }}
                  size='small'
                  className='filterButton'
                >
                  Очистить
                </Button>
                <Button
                  type='link'
                  size='small'
                  onClick={() => {
                    close()
                  }}
                >
                  Закрыть
                </Button>
              </div>
            )
          },
          filterIcon: () => (
            <FilterFilled
              style={{
                color: status ? '#1677ff' : 'rgba(0, 0, 0, 0.25)'
              }}
            />
          )
        }
      ]
      if (isSuperAdmin) {
        const [numberColumn, ...otherColumns] = baseColumns
        const buyerColumn: ColumnsType<ITableOrder>[0] = {
          title: 'Покупатель',
          dataIndex: 'buyerPhone',
          width: sellerId ? '30%' : '21%',
          onCell: ({ _id }) => {
            return {
              onClick: () => navigate(getOrderPath(_id))
            }
          },
          render: (_, item) => {
            return (
              <Typography.Text style={{ opacity: item?.user_id?.tel ? 1 : 0.5 }} onClick={(event) => event.stopPropagation()}>
                {item?.user_id?.tel ? phoneMaskStatic(item.user_id.tel) : 'Удаленный аккаунт'}
              </Typography.Text>
            )
          },
          filterDropdown: ({ close }) => {
            return (
              <div
                className='filterWrapper'
                onKeyDown={(e) => e.stopPropagation()}
              >
                <Input
                  ref={phoneBuyerSearchRef}
                  value={phoneBuyerSearch}
                  onChange={(e) => setPhoneBuyerSearch(e.target.value.replace(/[^0-9]/g, ''))}
                  onPressEnter={() => {
                    saveFieldValue({ tel: phoneBuyerSearch })
                    close()
                  }}
                  placeholder='Покупатель'
                  className='filterInput'
                />
                <div className='filterFooterButtons'>
                  <Button
                    type='primary'
                    onClick={() => {
                      saveFieldValue({ tel: phoneBuyerSearch })
                      close()
                    }}
                    icon={<SearchOutlined />}
                    size='small'
                    className='filterButton'
                  >
                    Найти
                  </Button>
                  <Button
                    onClick={() => {
                      setPhoneBuyerSearch('')
                      saveFieldValue({ tel: '' })
                      close()
                    }}
                    size='small'
                    className='filterButton'
                  >
                    Очистить
                  </Button>
                  <Button
                    type='link'
                    size='small'
                    onClick={() => {
                      close()
                    }}
                  >
                    Закрыть
                  </Button>
                </div>
              </div>
            )
          },
          onFilterDropdownOpenChange: (visible) => {
            if (visible) {
              setTimeout(() => phoneBuyerSearchRef.current?.select(), 100)
            }
          },
          filterIcon: () => (
            <SearchOutlined
              style={{
                color: phoneBuyerSearch ? '#1677ff' : 'rgba(0, 0, 0, 0.29)'
              }}
            />
          )
        }

        if (sellerId) {
          return [
            numberColumn,
            buyerColumn,
            ...otherColumns
          ]
        }
        return [
          numberColumn,
          {
            title: 'Продавец',
            dataIndex: 'seller_id',
            width: '21%',
            onCell: ({ _id }) => {
              return {
                onClick: () => navigate(getOrderPath(_id))
              }
            },
            render: (_, { seller_id }) => {
              return (
                <Typography.Text
                  style={{
                    opacity: seller_id?.shop_details?.shop_name ? 1 : 0.5
                  }}
                  onClick={(event) => event.stopPropagation()}
                >
                  {seller_id?.shop_details?.shop_name ?? 'Удаленный аккаунт'}
                </Typography.Text>
              )
            },
            filterDropdown: ({ close }) => {
              return (
                <div
                  className='filterWrapper'
                  onKeyDown={(e) => e.stopPropagation()}
                >
                  <Select
                    value={sellerSearch}
                    onChange={(value) => {
                      setSellerSearch(value)
                      setSelectedSellerId('')
                    }}
                    className='filterInput filterSelect'
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    placeholder='Выберите продавца'
                    onSelect={(_, item) => setSelectedSellerId(item.key)}
                    options={normalizedSellers}
                    showSearch
                    allowClear
                  />
                  <div className='filterFooterButtons'>
                    <Button
                      type='primary'
                      onClick={() => {
                        saveFieldValue({ seller_id: selectedSellerId })
                        close()
                      }}
                      icon={<SearchOutlined />}
                      size='small'
                      className='filterButton'
                    >
                      Найти
                    </Button>
                    <Button
                      onClick={() => {
                        setSellerSearch(undefined)
                        setSelectedSellerId('')
                        saveFieldValue({ seller_id: '' })
                        close()
                      }}
                      size='small'
                      className='filterButton'
                    >
                      Очистить
                    </Button>
                    <Button
                      type='link'
                      size='small'
                      onClick={() => {
                        close()
                      }}
                    >
                      Закрыть
                    </Button>
                  </div>
                </div>
              )
            },
            filterIcon: () => (
              <FilterFilled
                style={{
                  color: selectedSellerId ? '#1677ff' : 'rgba(0, 0, 0, 0.25)'
                }}
              />
            )
          },
          buyerColumn,
          ...otherColumns
        ]
      }
      return baseColumns
    },
    [
      orders,
      normalizedSellers,
      orderNumberSearch,
      sellerSearch,
      selectedSellerId,
      phoneBuyerSearch,
      status,
      priceOrder
    ]
  )

  return (
    <>
      <div style={{ paddingBottom: 80 }}>
        {!isSuperAdmin && <MainTitle text='Ваши заказы' />}
        {isSuperAdmin && !sellerId && <MainTitle text='Заказы' />}
        <div className={styles.header}>
          <Input
            value={globalSearch}
            onChange={(e) => {
              setGlobalSearch(e.target.value)
              debouncedGetOrdersByGlobalSearch(e.target.value)
            }}
            placeholder='Поиск'
            size='large'
            suffix={<SearchOutlined />}
            className={styles.headerField}
          />
          <DatePicker.RangePicker
            value={datePickerValue}
            rootClassName='myRangePicker'
            className={classNames(styles.headerField, styles.datePicker)}
            size='large'
            format='DD/MM/YYYY'
            placement='bottomLeft'
            locale={{
              ...localeRu,
              lang: {
                ...localeRu.lang,
                rangePlaceholder: ['От', 'До']
              }
            }}
            onCalendarChange={(value) => {
              setDatePickerValue(value)
              const fromValue = value?.[0]?.hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss') ?? ''
              const toValue = value?.[1]?.hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ss') ?? ''
              setSortData({
                from: fromValue,
                to: toValue
              })
              saveFieldValue({ from: fromValue, to: toValue })
            }}
            inputReadOnly
          />
        </div>
        <Table
          scroll={{
            x: sellerId ? 640 : 850
          }}
          columns={columns}
          dataSource={orders}
          loading={isLoading}
          rowKey={(el) => el._id}
          rowClassName='tableRow'
          onChange={(pagination, filters, sort: any) => {
            if (pagination.current === currentPage) {
              const value = sort.order === 'ascend'
                ? 'asc'
                : sort.order === 'descend'
                  ? 'desc'
                  : undefined
              setPriceOrder(value)
              onChangePriceOrder(value)
            }
          }}
          pagination={{
            current: currentPage,
            onChange: onPageChange, 
            pageSize,
            total,
            showSizeChanger: false
          }}
        />
      </div>
      <CancelOrder
        isOpen={openCancelOrderModal}
        onSuccess={changeToCanceledStatus}
        close={closeOrderCanceledModal}
      />
    </>
  )
}
