import React from 'react'
import {
  Input,
  Table,
  Tag,
  Typography,
  Space,
  Button,
  InputRef,
  Radio,
  Modal,
  message,
  TreeSelect,
  Select
} from 'antd'
import { MenuInfo } from 'rc-menu/lib/interface'
import {
  ContainerOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FilterFilled,
  SearchOutlined,
  ToTopOutlined,
  CopyOutlined,
  AppstoreAddOutlined,
  PlusOutlined
} from '@ant-design/icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import qs from 'qs'
import { AxiosError } from 'axios'
import debounce from 'lodash.debounce'
import { getProductEditPath } from 'routing/utils/getProductEditPath'
import {
  EActionsProductRequest,
  EProductStatus,
  EProductType,
  IParsedCategory,
  IProductQueryFilters,
  IProductResponse
} from 'models/product'
import { ECheckForFillingSellerDataError, ISeller } from 'models/seller'
import { MainTitle } from 'components/ui/MainTitle/MainTitle'
import { StatusDropdown } from 'components/ui/StatusDropdown/StatusDropdown'
import { ERoles } from 'models/auth'
import { productApi } from 'api/product'
import { sellerApi } from 'api/seller'
import { appSelectors } from 'store/app'
import noProductImage from 'assets/images/no-image-product.png'
import { IMAGES_URL } from 'shared/constants/urls'
import { DEBOUNCE_DELAY } from 'shared/constants/app'
import { getProductStatusItems, productTags } from 'shared/constants/product'
import { showServerError } from 'shared/utils/showServerError'
import { getNormalizedPrice } from 'shared/utils/getNormalizedPrice'
import { useAppSelector } from 'shared/hooks/useReduxHooks'
import { adminRoutes } from 'shared/constants/routes'
import { showMessage } from 'shared/utils/showMessage'
import { bodyScrollLock } from 'shared/utils/bodyScrollLock'
import { cleanObject } from 'shared/utils/cleanObject'
import styles from './GoodsList.module.scss'
import type { ColumnsType } from 'antd/es/table'

interface IGoodsListProps {
  sellerId?: string
}

enum ECategoryType {
  CATEGORY = 'category',
  SUB_CATEGORY = 'subCategory'
}

interface ISelectedCategory {
  id: string, 
  type: ECategoryType
}

export const GoodsList: React.FC<IGoodsListProps> = React.memo(
  ({ sellerId }) => {
    const navigate = useNavigate()

    const [, setSearchParams] = useSearchParams()
    const role = useAppSelector(appSelectors.selectRole)
    const userSellerId = useAppSelector(appSelectors.selectSellerId)

    const [windowWidth, setWindowWidth] = React.useState<number>(
      window.outerWidth
    )
    const [isFirstRequestFinished, setIsFirstRequestFinished] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const [products, setProducts] = React.useState<IProductResponse[]>([])
    const [sellers, setSellers] = React.useState<ISeller[]>([])
    const [categories, setCategories] = React.useState<IParsedCategory[]>([])

    const [selectedItemsId, setSelectedItemsId] = React.useState<string[]>([])

    const [globalSearch, setGlobalSearch] = React.useState('')

    const [nameSearch, setNameSearch] = React.useState('')
    const nameSearchRef = React.useRef<InputRef>(null)

    const [sellerSearch, setSellerSearch] = React.useState<string | undefined>(
      undefined
    )
    const [selectedSellerId, setSelectedSellerId] = React.useState('')

    const [categorySearch, setCategorySearch] = React.useState<
      string | undefined
    >(undefined)
    const [selectedCategory, setSelectedCategory] = React.useState<ISelectedCategory | null>(null)

    const [status, setStatus] = React.useState<EProductStatus | undefined>(
      undefined
    )

    const [currentPage, setCurrentPage] = React.useState(1)
    const [total, setTotal] = React.useState(0)
    const pageSize = 30

    const [isSellerDataFillingLoading, setIsSellerDataFillingLoading] =
      React.useState(false)

    const isSuperAdmin = React.useMemo(() => role === ERoles.ADMIN, [role])

    const normalizedSellers = React.useMemo(() => {
      return sellers.map((item) => ({
        label: item.shop_details.shop_name,
        value: item.shop_details.shop_name,
        key: item._id
      }))
    }, [sellers])

    React.useEffect(() => {
      const handleWindowResize = () => {
        setWindowWidth(window.outerWidth)
      }

      window.addEventListener('resize', handleWindowResize)

      return () => {
        window.removeEventListener('resize', handleWindowResize)
      }
    }, [])

    const showDeleteConfirm = () => {
      bodyScrollLock.enable()
      Modal.confirm({
        centered: true,
        title: 'Вы действительно хотите удалить выбранные товары?',
        content: 'Отменить данное действие будет невозможно.',
        icon: <ExclamationCircleOutlined />,
        okText: 'Удалить',
        cancelText: 'Закрыть',
        onOk() {
          return new Promise((resolve) => {
            setProductsAction(EActionsProductRequest.DELETE).then(resolve)
          })
        },
        onCancel() {
          bodyScrollLock.disable()
        }
      })
    } 

    const getProducts = async (skip: number, status: EProductStatus | undefined, data?: IProductQueryFilters) => {
      try {
        setIsLoading(true)
        const response = await productApi.getProducts({
          skip: String(skip),
          limit: String(pageSize),
          type: EProductType.PRODUCT,
          all_fields: data?.all_fields ?? globalSearch,
          status,
          name: data?.name ?? nameSearch,
          seller_id: sellerId ?? data?.seller_id ?? selectedSellerId,
          category_id:
            data?.category_id ??
            selectedCategory?.type === ECategoryType.CATEGORY
              ? selectedCategory?.id
              : '',
          subCategory_id:
            data?.subCategory_id ??
            selectedCategory?.type === ECategoryType.SUB_CATEGORY
              ? selectedCategory?.id
              : ''
        })
        setTotal(response.data.count)
        setProducts(response.data.products)
        setSearchParams(cleanObject({
          page: skip / pageSize + 1 === 1 ? '' : String(skip / pageSize + 1),
          all_fields: data?.all_fields ?? globalSearch,
          status: status ?? '',
          name: data?.name ?? nameSearch ?? '',
          seller_id: data?.seller_id ?? selectedSellerId ?? '',
          category_id:
            data?.category_id ??
            selectedCategory?.type === ECategoryType.CATEGORY
              ? (selectedCategory?.id as string)
              : '',
          subCategory_id:
            data?.subCategory_id ??
            selectedCategory?.type === ECategoryType.SUB_CATEGORY
              ? (selectedCategory?.id as string)
              : ''
        }))
      } catch (e) {
        showServerError(e)
      } finally {
        setIsLoading(false)
      }
    }

    const setProductsAction = async (action: EActionsProductRequest) => {
      try {
        const response = await productApi.setActions({
          [action]: selectedItemsId
        })
        message.success(response.data.message)
        if (selectedItemsId.length === sellers.length) {
          getProducts(0, status)
          setCurrentPage(1)
        } else {
          getProducts((currentPage - 1) * pageSize, status)
        }
        setSelectedItemsId([])
      } catch (e) {
        showServerError(e)
      } finally {
        bodyScrollLock.disable()
      }
    }

    React.useEffect(() => {
      if (isSuperAdmin && !sellerId) {
        const getSellers = async () => {
          try {
            const response = await sellerApi.getSearchSellers()
            setSellers(response.data)
          } catch (e) {
            showServerError(e)
          }
        }
        getSellers()
      }
      const getCategories = async () => {
        try {
          const response = await productApi.getCategories({
            parsed: 'true'
          })
          setCategories(response.data as IParsedCategory[])
        } catch (e) {
          showServerError(e)
        }
      }
      getCategories()
    }, [])

    React.useEffect(() => {
      if (window.location.search) {
        const params = qs.parse(window.location.search.substring(1))
        if (params.page) {
          setCurrentPage(Number(params.page))
        }
        if (params.all_fields) {
          setGlobalSearch(params.all_fields as string)
        }
        if (params.status) {
          setStatus(params.status as EProductStatus)
        }
        if (params.name) {
          setNameSearch(params.name as string)
        }
        if (params.seller_id) {
          setSelectedSellerId(params.seller_id as string)
        }
        if (params.category_id) {
          setSelectedCategory({ id: params.category_id as string, type: ECategoryType.CATEGORY })
        }
        if (params.subCategory_id) {
          setSelectedCategory({ id: params.subCategory_id as string, type: ECategoryType.SUB_CATEGORY })
        }
      }
      setIsFirstRequestFinished(true)
    }, [])

    React.useEffect(() => {
      if (sellers.length) {
        setSellerSearch(sellers.find(el => el._id === selectedSellerId)?.shop_details?.shop_name)
      }
    }, [sellers])

    React.useEffect(() => {
      if (categories.length) {
        if (selectedCategory && selectedCategory.type === ECategoryType.CATEGORY) {
          return setCategorySearch(categories.find(el => el.value === selectedCategory.id)?.title)
        }
        if (selectedCategory && selectedCategory.type === ECategoryType.SUB_CATEGORY) {
          for (const category of categories) {
            for (const subCategory of category.children) {
              if (subCategory.value === selectedCategory.id) {
                return setCategorySearch(subCategory.title)
              }
            }
          }
        }
      }
    }, [categories])

    React.useEffect(() => {
      if (isFirstRequestFinished) {
        getProducts((currentPage - 1) * pageSize, status)
        setCurrentPage(currentPage)
      }
    }, [isFirstRequestFinished])

    const onTagClick = React.useCallback(
      async (e: MenuInfo, itemId: string) => {
        try {
          setIsLoading(true)
          await productApi.setActions(
            e.key === EProductStatus.PUBLISHED
              ? { toPublish: itemId }
              : e.key === EProductStatus.DRAFT
                ? { toDraft: itemId }
                : { toReject: itemId }
          )
          setProducts((prev) =>
            prev.map((el) => {
              if (el._id === itemId) {
                return {
                  ...el,
                  status: e.key as EProductStatus
                }
              }
              return el
            })
          )
        } catch (e) {
          showServerError(e)
        } finally {
          setIsLoading(false)
        }
      },
      []
    )

    const onRowSelection = (selectedItemsId: React.Key[]) => {
      setSelectedItemsId(selectedItemsId as string[])
    }

    const onPageChange = (current: number) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      getProducts((current - 1) * pageSize, status)
      setCurrentPage(current)
    }

    const onSaveSearchName = (nameSearch: string) => {
      getProducts(0, status, { name: nameSearch })
      setCurrentPage(1)
    }

    const onSaveSeller = (sellerId: string) => {
      getProducts(0, status, { seller_id: sellerId })
      setCurrentPage(1)
    }

    const onChangeGlobalSearch = (searchValue: string) => {
      getProducts(0, status, { all_fields: searchValue })
      setCurrentPage(1)
    }

    const onChangeStatus = (newStatus: EProductStatus | undefined) => {
      getProducts(0, newStatus)
      setCurrentPage(1)
    }

    const debouncedGetProductsByGlobalSearch = React.useCallback(debounce(onChangeGlobalSearch, DEBOUNCE_DELAY), [nameSearch, selectedSellerId, selectedCategory, status])

    const onSaveCategory = (
      selectedCategory: ISelectedCategory | null
    ) => {
      if (!selectedCategory) {
        getProducts(0, status, { category_id: '', subCategory_id: '' })
        setSelectedCategory(null)
        return setCurrentPage(1)
      }
      if (selectedCategory.type === ECategoryType.CATEGORY) {
        getProducts(0, status, { category_id: selectedCategory.id })
        return setCurrentPage(1)
      }
      getProducts(0, status, { subCategory_id: selectedCategory.id })
      setCurrentPage(1)
    }

    const onPublish = () => {
      setProductsAction(EActionsProductRequest.PUBLISH)
    }

    const onDraft = () => {
      setProductsAction(EActionsProductRequest.DRAFT)
    }

    const onCopy = () => {
      setProductsAction(EActionsProductRequest.COPY)
    }

    const checkSellerDataOnFilling = async () => {
      try {
        setIsSellerDataFillingLoading(true)
        await sellerApi.checkSellerDataOnFilling(userSellerId as string)
        navigate(adminRoutes.GOODS_ADD)
      } catch (e) {
        const error = e as AxiosError<{
          message: ECheckForFillingSellerDataError
        }>
        if (
          error.response?.data.message ===
          ECheckForFillingSellerDataError.SELLER_INFO_IS_NOT_FILLED_OUT
        ) {
          showMessage(
            'error',
            'Перед добавлением товара, заполните все обязательные поля "Основной информации"',
            ''
          )
          return navigate(`${adminRoutes.PROFILE}/${adminRoutes.PROFILE_INFO}`)
        }
        if (
          error.response?.data.message ===
          ECheckForFillingSellerDataError.DELIVERY_WAYS_IS_NOT_EXIST
        ) {
          showMessage(
            'error',
            'Перед добавлением товара, выберите способы доставки вашего магазина',
            ''
          )
          return navigate(
            `${adminRoutes.PROFILE}/${adminRoutes.PROFILE_DELIVERY}`
          )
        }
        if (
          error.response?.data.message ===
          ECheckForFillingSellerDataError.SHOP_IS_NOT_EXIST
        ) {
          showMessage(
            'error',
            'Перед добавлением товара, заполните название и адрес вашего магазина',
            ''
          )
          return navigate(`${adminRoutes.PROFILE}/${adminRoutes.PROFILE_SHOPS}`)
        }
        if (
          error.response?.data.message ===
          ECheckForFillingSellerDataError.CDEK_POINT_IS_NOT_EXIST
        ) {
          showMessage(
            'error',
            'Перед добавлением товара, выберите пункт выдачи заказа для передачи заказов клиентов',
            ''
          )
          return navigate(`${adminRoutes.PROFILE}/${adminRoutes.PROFILE_SHOPS}`)
        }
        showServerError(e)
      } finally {
        setIsSellerDataFillingLoading(false)
      }
    }

    const isNotDesktop = windowWidth <= 1120

    const columns: ColumnsType<IProductResponse> = React.useMemo(() => {
      const baseColumns: ColumnsType<IProductResponse> = [
        {
          title: 'Артикул',
          dataIndex: 'vendor_code',
          width: isSuperAdmin && !sellerId ? '9%' : '12%',
          onCell: ({ _id }) => {
            return {
              onClick: () => navigate(getProductEditPath(_id))
            }
          },
          render: (_, { vendor_code }) => {
            return (
              <Typography.Text
                style={{ wordWrap: 'break-word' }}
                onClick={(event) => event.stopPropagation()}
              >
                {vendor_code}
              </Typography.Text>
            )
          }
        },
        {
          title: 'Фото',
          dataIndex: 'photo',
          width: 95,
          onCell: ({ _id }) => {
            return {
              onClick: () => navigate(getProductEditPath(_id))
            }
          },
          render: (_, { photo }) => {
            return photo && !!photo.length ? (
              <div
                style={{
                  width: 62,
                  height: 56,
                  backgroundImage: `url(${IMAGES_URL}${photo[0]})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat'
                }}
              />
            ) : (
              <img
                src={noProductImage}
                alt='Изображение товара'
                width={62}
                height={56}
              />
            )
          }
        },
        {
          title: 'Наименование товара',
          dataIndex: 'name',
          width: sellerId ? '24%' : isSuperAdmin ? '24%' : '34%',
          onCell: ({ _id }) => {
            return {
              onClick: () => navigate(getProductEditPath(_id))
            }
          },
          render: (_, { name }) => {
            return (
              <Typography.Text onClick={(event) => event.stopPropagation()}>
                {name}
              </Typography.Text>
            )
          },
          filterDropdown: ({ close }) => {
            return (
              <div
                className='filterWrapper'
                onKeyDown={(e) => e.stopPropagation()}
              >
                <Input
                  ref={nameSearchRef}
                  value={nameSearch}
                  onChange={(e) => setNameSearch(e.target.value)}
                  onPressEnter={() => {
                    onSaveSearchName(nameSearch)
                    close()
                  }}
                  placeholder='Наименование товара'
                  className='filterInput'
                />
                <div className='filterFooterButtons'>
                  <Button
                    type='primary'
                    onClick={() => {
                      onSaveSearchName(nameSearch)
                      close()
                    }}
                    icon={<SearchOutlined />}
                    size='small'
                    className='filterButton'
                  >
                    Найти
                  </Button>
                  <Button
                    onClick={() => {
                      setNameSearch('')
                      onSaveSearchName('')
                      close()
                    }}
                    size='small'
                    className='filterButton'
                  >
                    Очистить
                  </Button>
                  <Button
                    type='link'
                    size='small'
                    onClick={() => {
                      close()
                    }}
                  >
                    Закрыть
                  </Button>
                </div>
              </div>
            )
          },
          onFilterDropdownOpenChange: (visible) => {
            if (visible) {
              setTimeout(() => nameSearchRef.current?.focus(), 0)
            }
          },
          filterIcon: () => (
            <SearchOutlined
              style={{
                color: nameSearch ? '#1677ff' : 'rgba(0, 0, 0, 0.29)'
              }}
            />
          )
        },
        {
          title: 'Категория',
          dataIndex: 'category_id',
          width: sellerId ? '20%' : isSuperAdmin ? '19%' : '25%',
          onCell: ({ _id }) => {
            return {
              onClick: () => navigate(getProductEditPath(_id))
            }
          },
          render: (_, { category_id, subCategory_id }) => {
            if (!category_id || !subCategory_id) {
              return (
                <Typography.Text
                  onClick={(event) => event.stopPropagation()}
                  style={{ opacity: 0.5 }}
                >
                  Не задана
                </Typography.Text>
              )
            }
            return (
              <>
                <Typography.Text onClick={(event) => event.stopPropagation()}>
                  {category_id.name + ' >'}
                </Typography.Text>
                <br />
                <Typography.Text onClick={(event) => event.stopPropagation()}>
                  {subCategory_id.name}
                </Typography.Text>
              </>
            )
          },
          filterDropdown: ({ close }) => {
            return (
              <div
                className='filterWrapper'
                onKeyDown={(e) => e.stopPropagation()}
              >
                <TreeSelect
                  value={categorySearch}
                  onChange={(value) => {
                    setCategorySearch(value)
                    setSelectedCategory(null)
                  }}
                  className='filterInput filterTreeSelect'
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder='Выберите категорию или подкатегорию'
                  onSelect={(_, item) => {
                    setSelectedCategory({ id: item.value, type: item.type as ECategoryType })
                  }}
                  treeData={categories}
                  showSearch
                  treeNodeFilterProp='title'
                  allowClear
                />
                <div className='filterFooterButtons'>
                  <Button
                    type='primary'
                    onClick={() => {
                      onSaveCategory(selectedCategory)
                      close()
                    }}
                    icon={<SearchOutlined />}
                    size='small'
                    className='filterButton'
                  >
                    Найти
                  </Button>
                  <Button
                    onClick={() => {
                      setCategorySearch(undefined)
                      onSaveCategory(null)
                      close()
                    }}
                    size='small'
                    className='filterButton'
                  >
                    Очистить
                  </Button>
                  <Button
                    type='link'
                    size='small'
                    onClick={() => {
                      close()
                    }}
                  >
                    Закрыть
                  </Button>
                </div>
              </div>
            )
          },
          filterIcon: () => (
            <FilterFilled
              style={{
                color: selectedCategory ? '#1677ff' : 'rgba(0, 0, 0, 0.25)'
              }}
            />
          )
        },
        {
          title: 'Цена',
          dataIndex: 'price',
          width: sellerId && !isNotDesktop ? '100%' : '14%',
          onCell: ({ _id }) => {
            return {
              onClick: () => navigate(getProductEditPath(_id))
            }
          },
          render: (_, { price }) => {
            return (
              <Typography.Text
                onClick={(event) => event.stopPropagation()}
                style={{ opacity: price ? 1 : 0.5 }}
              >
                {price ? getNormalizedPrice(price) : 'Не задана'}
              </Typography.Text>
            )
          }
        },
        {
          title: 'Статус',
          dataIndex: 'status',
          width: '16%',
          onCell: ({ _id }) => {
            return {
              onClick: () => navigate(getProductEditPath(_id))
            }
          },
          render: (_, { status, _id }) => {
            if (role === ERoles.USER && status === EProductStatus.MODERATION) {
              return (
                <Tag
                  color={productTags[status].color}
                  onClick={(event) => event.stopPropagation()}
                >
                  {productTags[status].label}
                </Tag>
              )
            }
            return (
              <div onClick={(event) => event.stopPropagation()}>
                <StatusDropdown
                  items={getProductStatusItems(status, role as ERoles)}
                  label={productTags[status].label}
                  color={productTags[status].color}
                  onClick={(e) => onTagClick(e, _id)}
                />
              </div>
            )
          },
          filterDropdown: ({ close }) => {
            return (
              <div
                className='filterWrapper'
                onKeyDown={(e) => e.stopPropagation()}
              >
                <Radio.Group
                  onChange={(e) => {
                    setStatus(e.target.value)
                    onChangeStatus(e.target.value)
                    close()
                  }}
                  value={status}
                  style={{ display: 'block', marginBottom: 15 }}
                >
                  <Space direction='vertical' size={15}>
                    {Object.values(productTags).map((item) => (
                      <Radio key={item.value as string} value={item.value}>
                        <Tag color={item.color}>{item.label}</Tag>
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
                <Button
                  onClick={() => {
                    setStatus(undefined)
                    onChangeStatus(undefined)
                    close()
                  }}
                  size='small'
                  className='filterButton'
                >
                  Очистить
                </Button>
                <Button
                  type='link'
                  size='small'
                  onClick={() => {
                    close()
                  }}
                >
                  Закрыть
                </Button>
              </div>
            )
          },
          filterIcon: () => (
            <FilterFilled
              style={{
                color: status ? '#1677ff' : 'rgba(0, 0, 0, 0.25)'
              }}
            />
          )
        }
      ]
      if (isSuperAdmin && !sellerId) {
        const [numberColumn, photoColumn, nameColumn, ...otherColumns] =
          baseColumns
        const sellerColumn: ColumnsType<IProductResponse>[0] = {
          title: 'Продавец',
          dataIndex: 'shop_name',
          width: '19%',
          onCell: ({ _id }) => {
            return {
              onClick: () => navigate(getProductEditPath(_id))
            }
          },
          render: (_, { seller_id }) => {
            return (
              <Typography.Text
                style={{
                  opacity: seller_id?.shop_details?.shop_name ? 1 : 0.5
                }}
                onClick={(event) => event.stopPropagation()}
              >
                {seller_id?.shop_details?.shop_name ?? 'Удаленный аккаунт'}
              </Typography.Text>
            )
          },
          filterDropdown: ({ close }) => {
            return (
              <div
                className='filterWrapper'
                onKeyDown={(e) => e.stopPropagation()}
              >
                <Select
                  value={sellerSearch}
                  onChange={(value) => {
                    setSellerSearch(value)
                    setSelectedSellerId('')
                  }}
                  className='filterInput filterSelect'
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder='Выберите продавца'
                  onSelect={(_, item) => setSelectedSellerId(item.key)}
                  options={normalizedSellers}
                  showSearch
                  allowClear
                />
                <div className='filterFooterButtons'>
                  <Button
                    type='primary'
                    onClick={() => {
                      onSaveSeller(selectedSellerId)
                      close()
                    }}
                    icon={<SearchOutlined />}
                    size='small'
                    className='filterButton'
                  >
                    Найти
                  </Button>
                  <Button
                    onClick={() => {
                      setSellerSearch(undefined)
                      setSelectedSellerId('')
                      onSaveSeller('')
                      close()
                    }}
                    size='small'
                    className='filterButton'
                  >
                    Очистить
                  </Button>
                  <Button
                    type='link'
                    size='small'
                    onClick={() => {
                      close()
                    }}
                  >
                    Закрыть
                  </Button>
                </div>
              </div>
            )
          },
          filterIcon: () => (
            <FilterFilled
              style={{
                color: selectedSellerId ? '#1677ff' : 'rgba(0, 0, 0, 0.25)'
              }}
            />
          )
        }
        return [
          numberColumn,
          photoColumn,
          nameColumn,
          sellerColumn,
          ...otherColumns
        ]
      }
      return baseColumns
    }, [
      products,
      normalizedSellers,
      categories,
      nameSearch,
      sellerSearch,
      selectedSellerId,
      categorySearch,
      selectedCategory,
      status,
      isSuperAdmin,
      isNotDesktop,
      sellerId
    ])

    return (
      <div style={{ paddingBottom: 80 }}>
        {!sellerId && (
          <MainTitle text={isSuperAdmin ? 'Товары' : 'Ваши товары'} />
        )}
        <div className={styles.header}>
          <Input
            value={globalSearch}
            onChange={(e) => {
              setGlobalSearch(e.target.value)
              debouncedGetProductsByGlobalSearch(e.target.value)
            }}
            placeholder='Поиск'
            size='large'
            suffix={<SearchOutlined />}
            className='searchInput'
          />
          {!isSuperAdmin && !sellerId && (
            <>
              <Button
                type='primary'
                size='large'
                icon={<AppstoreAddOutlined />}
                loading={isSellerDataFillingLoading}
                className={styles.addProductButton}
                onClick={checkSellerDataOnFilling}
              >
                Добавить товар
              </Button>
              <Button
                type='primary'
                size='large'
                icon={<PlusOutlined />}
                loading={isSellerDataFillingLoading}
                className={styles.addProductButtonMobile}
                onClick={checkSellerDataOnFilling}
              />
            </>
          )}
        </div>
        <Table
          scroll={
            sellerId && !isNotDesktop
              ? undefined
              : {
                x: !sellerId ? 1050 : 900
              }
          }
          columns={columns}
          dataSource={products}
          loading={isLoading}
          rowKey={(el) => el._id}
          rowSelection={{
            selectedRowKeys: selectedItemsId,
            onChange: onRowSelection
          }}
          rowClassName='tableRow'
          footer={() => (
            <div className={styles.footer}>
              <Typography.Text>Действия:</Typography.Text>
              <Button
                type='primary'
                icon={<ToTopOutlined />}
                disabled={!selectedItemsId.length}
                onClick={onPublish}
              >
                Опубликовать
              </Button>
              <Button
                danger
                type='primary'
                icon={<DeleteOutlined />}
                onClick={showDeleteConfirm}
                disabled={!selectedItemsId.length}
              >
                Удалить
              </Button>
              {!isSuperAdmin && (
                <Button
                  icon={<CopyOutlined />}
                  onClick={onCopy}
                  disabled={!selectedItemsId.length}
                >
                  Дублировать
                </Button>
              )}
              <Button
                icon={<ContainerOutlined />}
                disabled={!selectedItemsId.length}
                onClick={onDraft}
              >
                В черновик
              </Button>
            </div>
          )}
          pagination={{
            current: currentPage,
            onChange: onPageChange,
            pageSize,
            total,
            showSizeChanger: false
          }}
        />
      </div>
    )
  }
)
