import { privateApi } from 'api'
import { AxiosResponse } from 'axios'
import { EHorseStatus, IActionsHorseRequest, IAdminHorsesQueryResponse, IHorseQueryRequest } from 'models/horse'

export const horsesApi = {
  getHorses({
    skip,
    limit
  }: IHorseQueryRequest): Promise<AxiosResponse<IAdminHorsesQueryResponse>> {
    const params = new URLSearchParams({
      skip,
      limit
    })
    return privateApi.get<IAdminHorsesQueryResponse>(
      `admin/horse?${params.toString()}`
    )
  },

  changeHorseStatus(horseId: string, status: EHorseStatus): Promise<AxiosResponse> {
    return privateApi.put(
      `admin/horse/change-status/${horseId}`,
      { status }
    )
  },
  setActions({
    toDelete
  }: IActionsHorseRequest): Promise<AxiosResponse> {
    const params = new URLSearchParams({
      toDelete: toDelete
    })

    return privateApi.put<void>(`admin/horse/actions?${params.toString()}`)
  }
}
