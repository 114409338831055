import { ERecieveMethod } from 'models/orders'

export const getDeliveryTypeName = (type: keyof typeof ERecieveMethod): string => {
  switch (type) {
  case ERecieveMethod.COURIER:
    return 'Доставка курьером СДЭК'
  case ERecieveMethod.PICKUP_POINT:
    return 'Самовывоз из магазина'
  default:
    return 'Доставка в пункт выдачи СДЭК'
  }
}